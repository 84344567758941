export const drivers_sem2 = [
    { student_id: 2, name: "Jamie Doyle", time: 22.198 },
    { student_id: 3, name: "Rian Doyle", time: 22.243 },
    { student_id: 6, name: "Hannah O'Connor", time: 22.351 },
    { student_id: 14, name: "Sam Boyle", time: 21.906 },
    { student_id: 16, name: "Ryan Sexton", time: 22.781 },
    { student_id: 17, name: "Richie Murphy", time: 21.797 },
    { student_id: 18, name: "Ellen Donnelly", time: 22.476 },
    { student_id: 23, name: "Jack Gear", time: 21.859 },
    { student_id: 28, name: "Cillian O'Connor", time: 22.195 },
    { student_id: 29, name: "Simon Harte", time: 22.359 },
    { student_id: 32, name: "Nathan Fitzgerald", time: 22.45 },
    { student_id: 34, name: "Evan O'Neill", time: 22.615 },
    { student_id: 62, name: "Colm Power Pierce", time: 22.228 },
    { student_id: 63, name: "Jamie Malone", time: 23.234 },
    { student_id: 64, name: "Jessica Flynn", time: 22.795 },
    { student_id: 65, name: "Niamh O'Keeffe", time: 25.166 },
    { student_id: 66, name: "Leah O'Donoghue", time: 23.952 },
    { student_id: 67, name: "Shannon Bennett", time: 24.842 },
    { student_id: 68, name: "Ayo", time: 25.317 },
    { student_id: 69, name: "Daniel Seve", time: 26.844 },
    { student_id: 70, name: "Taisce Ryan", time: 28.901 },
    { student_id: 71, name: "Patrick Whelan", time: 22.822 },
    { student_id: 72, name: "Niall Doherty", time: 22.812 },
    { student_id: 73, name: "Scott Bowdren", time: 22.748 },
    { student_id: 74, name: "Ryan Kelly", time: 23.385 },
    { student_id: 75, name: "Jack Murphy", time: 23.387 },
    { student_id: 76, name: "Peter Murphy", time: 24.147 },
    { student_id: 77, name: "Justin Whelan", time: 24.555 },
    { student_id: 79, name: "Megan        ", time: 25.688 }, //surname
    { student_id: 80, name: "Becky        ", time: 27.321 }, //surname
    { student_id: 81, name: "Shane Nolan", time: 25.692 },
    { student_id: 82, name: "Aine Whelan", time: 29.172 },
    { student_id: 83, name: "Jasmine O'Shea Rush", time: 29.676 },
    { student_id: 26, name: "Adam Raher", time: 23.762 },
    { student_id: 84, name: "Daniel Greany", time: 27.179 },
    { student_id: 10, name: "Darragh Whitney", time: 22.314 },
    { student_id: 12, name: "Ronan Duggan", time: 22.372 },
    { student_id: 85, name: "Colin Kelly", time: 22.203 },
    { student_id: 1, name: "Ozzy Domarkas", time: 22.185 },
    { student_id: 86, name: "Niall O'Brien", time: 22.604 },
    { student_id: 87, name: "Stephan Price", time: 26.898 },
    { student_id: 88, name: "Ciara Burke", time: 27.172 },
    { student_id: 89, name: "Ava Carr", time: 32.217 },
    { student_id: 50, name: "Rosanne Coe", time: 25.642 },
    { student_id: 7, name: "Nathan McNamara", time: 25.742},
    { student_id: 90, name: "Michael Corr", time: 24.545 },


];
export default drivers_sem2;
